import React, { FC } from 'react';
import { graphql } from 'gatsby';

import SEO from 'src/components/SEO';
import Layout from 'src/components/Layout';
import Content from 'src/components/Content';

const PPPage: FC = () => (
  <Layout>
    <Content>
      <h2>GENERAL INFORMATION</h2>
      <p>
        In this privacy policy references to “the website” are references to
        www.omniget.com, references to “we”, “us” and “our” are references to
        Adrelija, MB, company code 304990727, address of seat Alksnyno g. 10,
        Panevėžys, Lithuania, e-mail: info@omniget.com.
      </p>
      <p>
        This privacy policy outlines the conditions of how we process your
        personal data as well as the procedure for the exercise of your rights.
        We may review and amend the privacy policy at any time, and we therefore
        recommend that you make sure on a regular basis that you are familiar
        with the updated (effective)version of the privacy policy.
      </p>
      <p>
        Your personal data are processed pursuant to the legislation in force
        and by implementing appropriate technical and organisational security
        measures
      </p>
      <p>
        We use the personal data which you provide or which we collect only for
        the purposes specified in the privacy policy. Your personal data are
        transferred to the data processors, providing the website’s hosting and
        support services, newsletter mailing services. In cases stipulated by
        law, your data may also be transferred to competent authorities. Other
        cases in which your personal data may be transferred to third parties
        (our partners, data processors, etc.) are specified in individual
        sections of the privacy policy.
      </p>
      <p>
        The website contains links to websites which are not owned by us. We
        shall not be liable for any privacy policies applied by such websites,
        and we therefore recommend you to be proactive and read privacy policies
        applied to a website to which you are redirected.
      </p>
      <h2>COOKIES</h2>
      <p>
        <b>On the website</b>
      </p>
      <p>
        The website uses cookies, which are small text files placed on your
        device (e.g. desktop computer, mobile phone, tablet), when you browse
        the website.
      </p>
      <p>We need the cookies on the website to be able to do the following:</p>
      <ul>
        <li>Ensure the appropriate functioning of the website;</li>
        <li>
          Ensure the optimal functioning speed and security of the website;
        </li>
        <li>
          Find out about visits to the website and its individual
          pages/sections, analyse the website visitor flows (visit date and
          time, specific browsers used, specific device types used and sizes of
          the screens of the devices used) and in this manner improve the
          website on an ongoing basis and satisfy your needs better.
        </li>
      </ul>
      <p>
        The specifications of the cookies used by us are provided in the table
        below.
      </p>
      <table
        style={{
          borderCollapse: 'collapse',
          width: '100%',
          border: '1px solid #ddd',
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                textAlign: 'left',
                backgroundColor: '#f2f2f2',
              }}
            >
              TYPE
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                textAlign: 'left',
                backgroundColor: '#f2f2f2',
              }}
            >
              NAME OF THE COOKIE
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                textAlign: 'left',
                backgroundColor: '#f2f2f2',
              }}
            >
              FUNCTION OF THE COOKIE
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                textAlign: 'left',
                backgroundColor: '#f2f2f2',
              }}
            >
              EXPIRY
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              <b>Statistical</b>
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>_ga</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              Used to distinguish unique users.
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              2 years
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              <b>Statistical</b>
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>_gid</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              Used to distinguish unique users.
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              24 hours
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              <b>Statistical</b>
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>_gat</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              Does not store any information. Used to throttle request rate.
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              1 minute
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              <b>Analytical</b>
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              session
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              Used to distinguish unique users, for the purpose of adjusting the
              functionality of the website.
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              2 years
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        You can delete, restrict or block cookies by controlling your web
        browser settings. If you want websites not to place any cookies on your
        device, customize your web browser settings so that you are notified
        before any cookie is placed or your browser rejects all cookies. You
        will need to customize the settings for each internet browser on each
        device.
      </p>
      <p>
        By blocking or restricting the use of cookies, you may not receive the
        services you require or may not use the functionality of the website
      </p>
      <p>
        You can find more information about cookies, how they work, and their
        settings at http://www.allaboutcookies.org.
      </p>
      <p>
        <b>On social networks</b>
      </p>
      <p>We administer a fan page on LinkedIn (hereinafter the Fan Page)</p>
      <p>
        When you visit the Fan Pages, the administrators of the social networks
        place cookies on your device, and these cookies collect your personal
        data. Cookies are placed on your device both in case you are a
        registered user of the respective social network and in case you do not
        have an account on the respective social network. We do not have access
        to the collected personal data and only receive from the administrators
        of the social network statistical information about visits to the Fan
        Pages.
      </p>
      <p>
        To receive comprehensive information about your personal data processed
        for the purpose of administration of the Fan Pages, please read the
        LinkedIn privacy policy, or contact the LinkedIn data protection officer
        (regarding the personal data used by LinkedIn).
      </p>
      <p>
        Your rights related to processing of your personal data are specified in
        the EXERCISE OF THE RIGHTS section of the privacy policy.
      </p>
      <h2>COMMUNICATION TOOLS</h2>
      <p>
        The website provides an opportunity to contact us in several ways, i.e.
        by sending an inquiry via the contact form, by contacting via LinkedIn
        or by e-mailing to the e-mail address specified on the website.
      </p>
      <p>
        For the purpose of responding to your inquiry and saving evidence of
        correspondence (implementing the legitimate interest to ensure the
        effective communication and prevent any disputes related to customer
        service), we process the following information that you provide:
      </p>
      <ul>
        <li>
          By sending an inquiry via the contact form, name, surname, company
          name, e-mail address and phone number, date and time of receipt of the
          inquiry is being processed.
        </li>
        <li>
          By communicating via LinkedIn the following data is processed: name,
          surname, photo (if used), date and time of the inquiry and content of
          correspondence with our representative.
        </li>
        <li>
          By communicating via the e-mail address specified on the website, the
          following data is processed: name, surname, e-mail address, e-mail
          date and time and content of correspondence with our representative.
        </li>
      </ul>
      <p>
        In case of sending an inquiry via LinkedIn account, the personal data
        you provide may be disclosed to the social network manager and other
        persons with whom LinkedIn manager shares personal data. For more
        information please read the privacy policy of LinkedIn.
      </p>
      <p>
        The personal data provided together with inquiries and any subsequent
        correspondence between you and our representative will be stored for a
        term required to accomplish the respective task and ensure the exercise
        of our rights.
      </p>
      <p>
        Your rights related to processing of your personal data are specified in
        the EXERCISE OF THE RIGHTS section of the privacy policy.
      </p>
      <h2>CLIENT AREA USERS</h2>
      <p>
        If you are our client, at your request we provide you the Client Area
        account. We process the following personal data for the purpose of
        administration of the Client Area: name, surname, company name and
        e-mail address. The legal basis for the processing of personal data is
        your consent.
      </p>
      <p>
        As our client you may choose to collect printing data based on
        particular user or track the names of the files being printed. In
        respect of such data you shall act as the data controller and we - as
        the data processor. By choosing such an option you warrant that you
        collect and process the abovementioned personal data of your device
        users in accordance with the law. Should you decide not to collect the
        abovementioned data, we will not process these data, either.
      </p>
      <p>
        We store the personal data collected for the purpose of administration
        of the Client Area until you delete your account or for 3 years after
        the last sign in.
      </p>
      <p>
        You can delete your account by e-mailing your request to us. The account
        will be deleted within 10 business days from the date we confirm about
        the receipt of your request. You will receive an e-mail notification
        about the deletion of your account.
      </p>
      <p>
        Your rights related to processing of your personal data are specified in
        the EXERCISE OF THE RIGHTS section of the privacy policy.
      </p>
      <h2>NEWSLETTERS</h2>
      <p>
        You may subscribe to receiving newsletters or other information from us.
        In order to subscribe to receiving newsletters, you should provide your
        e-mail address. Also, we suggest to provide your name and surname as
        well as company name, which is optional.
      </p>
      <p>
        You can unsubscribe from the mailing list at any time by clicking the
        link available in each newsletter or by e-mailing your request to us.
        Unless you withdraw your consent to process your personal data for
        direct marketing purposes, your personal data will be stored for 3 years
        from the date of the subscription to the newsletter.
      </p>
      <p>
        Your personal data are transferred to the data processors, providing
        newsletter mailing services.
      </p>
      <p>
        Your rights related to the processing of your personal data are
        specified in the EXERCISE OF THE RIGHTS section of the privacy policy.
      </p>
      <h2>PERSONAL DATA OF PROSPECTIVE EMPLOYEES</h2>
      <p>
        We process personal data of candidates for employees for the purpose of
        employee selection and employment, implementing legitimate interest to
        evaluate a candidate’s suitability for the respective position.
      </p>
      <p>
        We receive your personal data directly from you when you send your CV to
        us, as well as from agencies providing employment services to us. Your
        data, provided to us, may be transferred to the employment agency,
        performing employee selection
      </p>
      <p>
        In addition to the data you provide, we may collect and process in other
        ways your other publicly accessible data, related to qualifications,
        professional skills and competencies, i.e. look for information on the
        internet, check your profiles on social networks (e.g. LinkedIn,
        Facebook, Twitter), etc. We may also contact your former employers and
        ask them to provide information about your qualifications, professional
        skills and competencies.
      </p>
      <p>
        We store the data you provide and/or the data collected by us
        independently, for 4 months from the end of the respective selection
        procedure. If you apply without an announced employee search, the
        personal data are stored for 6 months from the day of application. If a
        consent to the further processing of your personal data is not obtained,
        then, on expiry of the said term, all your personal data (both the data
        you provided and the data collected by us) are deleted.
      </p>
      <p>
        Your rights related to the processing of your personal data are
        specified in the EXERCISE OF THE RIGHTS section of the privacy policy.
      </p>
      <h2>EXERCISE OF THE RIGHTS</h2>
      <p>You have the following rights:</p>
      <table
        style={{
          borderCollapse: 'collapse',
          width: '100%',
          border: '1px solid #ddd',
        }}
      >
        <tbody>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              The right of access to personal data processed by us
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              The right of access to personal data and the right to request
              rectification of inaccurate personal data may be implemented by
              sending a written application to our postal address or to the
              e-mail address info@omniget.com (in case an application signed
              with an electronic signature is presented).
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              The right to request rectification of inaccurate or
              incomprehensive personal data
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              In your application to access your personal data, please specify
              categories of personal data you wish to access as well as types of
              information listed in Article 15(1) of the GDPR you wish to
              receive. In your application to request rectification of
              inaccurate or incomprehensive personal data, please specify
              personal data you wish to rectify.
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              The right to erasure of unlawfully processed personal data
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              This right shall be exercised by sending a written application to
              our postal address or to the e-mail address info@omniget.com (in
              case an application signed with an electronic signature is
              presented). We delete your data within 30 days from the receipt of
              the application or refuse to fulfil the request and inform of the
              reasons in writing.
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              The right to restriction of the processing of personal data until
              the purpose for which the personal data is processed is verified,
              until it is established whether your interests supersede our
              interests or not, as well as in cases where personal data is
              processed unlawfully, but you disagree that this data be deleted
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              This right shall be exercised by sending a written application to
              our postal address or to the e-mail address info@omniget.com (in
              case an application signed with an electronic signature is
              presented). If you request that the processing of only some of
              your personal data processed by us be restricted, the application
              must specify the categories of personal data the processing of
              which should be restricted. If the application is reasoned, the
              processing of personal data shall be restricted within 5 business
              days from receipt of the application.
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              The right to data portability
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              This right shall be exercised by sending a written application to
              our postal address or to the e-mail address info@omniget.com (in
              case an application signed with an electronic signature is
              presented). If you request the transfer of only some of the
              personal data processed by us, the application must specify the
              categories of personal data in respect of which the right to data
              portability is being exercised. If the application is reasoned,
              we, within 30 days from the receipt of the application, provide
              you or the data controller specified by you your personal data in
              a computer-readable format (depending on your request, systemised
              personal data shall be sent by e-mail or recorded on an external
              data storage device).
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              The right to object to the processing of personal data
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              This right shall be exercised by sending a written application to
              our postal address or to the e-mail address info@printlog.com (in
              case an application signed with an electronic signature is
              presented). If you object to the processing of your personal data,
              it will be assessed whether your legitimate interest, with regard
              to which you object to the processing of your personal data,
              overrides our legitimate interest, on which the processing of your
              personal data is based.
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              The right to lodge a complaint with the competent supervisory
              authority
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              This right may be exercised by lodging a complaint with the State
              Data Protection Inspectorate in connection with any of our actions
              related to the processing of your personal data
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>
          In cases you exercise your rights by sending a written application by
          mail to our postal address, the application must be accompanied by a
          notarised copy of your ID document (passport, ID card)
        </strong>
      </p>
      <h2>CONTACT INFORMATION</h2>
      <p>
        Should you have any questions regarding the privacy policy, please do
        not hesitate to contact us and request assistance.
      </p>
    </Content>
  </Layout>
);

export default PPPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO title="Privacy Policy | OmniGet" description="OmniGet privacy policy." />
);
